import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function FullScreenLoader({ className }: Props): JSX.Element {
  return (
    <Styled.Container className={className}>
      <Styled.Loader />
    </Styled.Container>
  );
}

export default memo(FullScreenLoader);
