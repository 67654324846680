import { format } from 'date-fns/fp';

/*
 * Guide for the application date formats.
 * For reference see https://date-fns.org/docs/format
 */

/*
 * MAIN FORMATS
 *
 * The following formats are general purpose formats to be used anywhere between
 * the app. It is advised the format that fits the most.
 *
 * This list of formats should be kept short, and only expanded after careful
 * consideration. Keeping this list short will provide consistency across the
 * app.
 */

const MONTH_DAY_FORMAT = 'MMM d'; // Dec 29
const MONTH_DAY_YEAR_FORMAT = 'MMMM d, yyyy'; // September 1, 2021
const MONTH_DAY_YEAR_SHORT_FORMAT = 'MMM d, yyyy'; // Sept 1, 2021
const MONTH_DAY_YEAR_SHORT_NUMERIC = 'MM/dd/yyyy';
const YEAR_MONTH_DAY_SHORT_NUMERIC_DASH = 'yyyy-MM-dd';

export const formatMonthDay = format(MONTH_DAY_FORMAT);
export const formatMonthDayYear = format(MONTH_DAY_YEAR_FORMAT);
export const formatMonthDayYearShort = format(MONTH_DAY_YEAR_SHORT_FORMAT);
export const formatMonthDayYearNumeric = format(MONTH_DAY_YEAR_SHORT_NUMERIC);
export const formatYearMonthDayNumericDash = format(
  YEAR_MONTH_DAY_SHORT_NUMERIC_DASH,
);

export function formatAvailability({
  availableFrom,
}: {
  availableFrom: number | undefined;
}): string {
  const currentDate = new Date();
  let availabilityString = '';
  if (availableFrom) {
    const availableDate = new Date(availableFrom);
    if (availableDate && currentDate > availableDate) {
      availabilityString = 'Currently available';
    } else {
      availabilityString = `Available on ${formatMonthDayYearShort(
        availableDate,
      )}`;
    }
  } else {
    availabilityString = 'Submit interest for availability';
  }

  return availabilityString;
}
