import Button from '$/components/Atoms/Button';
import HomeStatus from '$/containers/Views/Home/types';
import { useGiveNotice } from '$/containers/Views/RenewalOffer/logic';
import { formatMonthDayYear } from '$/utils/dates';
import { differenceInCalendarDays, isPast } from 'date-fns';
import { memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import * as Styled from './styles';
import type { Props } from './types';
import Notification from '../../Molecules/CardNotificationTag';

function LeaseCard({
  unit,
  buttonText,
  className,
  leaseToDate,
  onClick,
  price,
  hasMoveOutOption,
  renewalExpiryDate,
  homeStatus,
  moveOutDate,
}: Props): JSX.Element {
  const theme = useContext(ThemeContext);
  const { customTheme } = theme;
  const {
    name: title,
    address: { street: address, secondary, city, state, zipCode: postalCode },
    coverImageId: imageUrl,
    beds: bedrooms,
    baths: bathrooms,
  } = unit || {};
  const daysToRenew =
    renewalExpiryDate &&
    differenceInCalendarDays(renewalExpiryDate, Date.now());
  const expiredOrDeclined =
    (daysToRenew && daysToRenew < 0) ||
    homeStatus === HomeStatus.RenewalDeclined;
  const formattedDate = leaseToDate && formatMonthDayYear(leaseToDate);
  const formattedMoveOutDate = moveOutDate && formatMonthDayYear(moveOutDate);
  const leaseToDateValid = leaseToDate && !isPast(leaseToDate);
  const moveOutNotification = () => {
    const moveOutCopy =
      formattedMoveOutDate && `Move out date: ${formattedMoveOutDate}`;
    switch (true) {
      case Boolean(moveOutDate && moveOutCopy):
        return (
          <Notification color={customTheme.colors.error100}>
            {moveOutCopy}
          </Notification>
        );
      case leaseToDateValid:
        return (
          <Notification color={customTheme.colors.warning100}>
            Lease end date: {formattedDate}
          </Notification>
        );
      default:
        return undefined;
    }
  };
  const renewalString = (renewalDays: number) => {
    if (typeof daysToRenew !== 'undefined') {
      if (renewalDays === 0) {
        return `Last day to renew`;
      }
      return `${daysToRenew} days left to renew`;
    }
    return ``;
  };

  // move decline button code into it's own component & reuse on renewal offer page
  const { handleGiveNotice } = useGiveNotice();
  const declineProps: {
    label: string;
    onClick: () => void;
    variant: 'primary' | 'secondary' | 'disruptive' | 'floating' | undefined;
    icon: JSX.Element;
  } = {
    label: `I want to move out`,
    onClick: handleGiveNotice,
    variant: 'disruptive',
    icon: <Styled.TruckIcon />,
  };

  return (
    <Styled.Container className={className}>
      {imageUrl && (
        <Styled.ImageContainer $hasImage={!!imageUrl}>
          {imageUrl && <Styled.Image publicId={imageUrl} />}
          {typeof daysToRenew !== 'undefined' &&
            daysToRenew >= 0 &&
            !expiredOrDeclined && (
              <Notification>{renewalString(daysToRenew)}</Notification>
            )}
          {expiredOrDeclined && moveOutNotification()}
        </Styled.ImageContainer>
      )}

      <Styled.Content $hasImage={!!imageUrl}>
        <Styled.Top>
          <Styled.Title $size="xl" $weight="bold">
            {title}
          </Styled.Title>
          <Styled.Subtitle $weight="regular">
            {address}
            {secondary && `, ${secondary}`}
            <br />
            {city}, {state} {postalCode}
          </Styled.Subtitle>
        </Styled.Top>
        <div>
          <Styled.Flex $hasButton={!!buttonText && !!onClick}>
            <Styled.Price $size="xl" $weight="bold">
              ${price.toLocaleString('en-US')}
            </Styled.Price>
            <Styled.Item>
              <Styled.BedIcon aria-label="Bedrooms amount" />
              <Styled.Amount>{bedrooms}</Styled.Amount>
            </Styled.Item>
            <Styled.Item>
              <Styled.BathtubIcon aria-label="Bathrooms amount" />
              <Styled.Amount>{bathrooms}</Styled.Amount>
            </Styled.Item>
          </Styled.Flex>
          <Styled.Disclaimer $size="xs">
            Current rent amount, excludes fees
          </Styled.Disclaimer>
        </div>

        {buttonText && onClick && (
          <Styled.ButtonWrapper>
            <Button label={buttonText} onClick={onClick} />
            {hasMoveOutOption && <Styled.ButtonDisruptive {...declineProps} />}
          </Styled.ButtonWrapper>
        )}
      </Styled.Content>
    </Styled.Container>
  );
}

export default memo(LeaseCard);
