import { from } from '$/styles/responsive';
import DefaultTimerIcon from 'remixicon-react/TimerLineIcon';
import styled from 'styled-components';

import { Caption } from '../../Atoms/Typography';

export const Notification = styled(Caption)`
  color: ${({ theme }) => theme.customTheme.colors.black};
  ${from.tabletLandscape`
    font-size: 0.875rem;
  `}
`;

export const NotificationWrapper = styled.div<{ $color: string }>`
  align-items: center;
  display: flex;
  margin-top: auto;
  padding: 0.563rem 1.156rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${(props) => props.$color};
  ${from.tabletLandscape`
    border-radius: 1rem 0 0 1rem;
    bottom: 1.5rem;
    right: 0;
    width: auto;
    padding: 0.875rem 1.125rem;
  `};
`;

export const TimerIcon = styled(DefaultTimerIcon)`
  color: ${({ theme }) => theme.customTheme.colors.black};
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.656rem;
  width: 1.25rem;
`;
