import DefaultBathtubIcon from '$/assets/icons/bathtub.svg';
import DefaultBedIcon from '$/assets/icons/bed.svg';
import DefaultButton from '$/components/Atoms/Button';
import DefaultImage from '$/components/Atoms/Image';
import { Body1, Body2, Caption, Text } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import DefaultTimerIcon from 'remixicon-react/TimerLineIcon';
import DefaultTruckIcon from 'remixicon-react/TruckLineIcon';
import styled, { css } from 'styled-components';

export const Amount = styled(Body2).attrs({ as: 'span' })``;

export const Flex = styled.div<{ $hasButton: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const iconStyles = css`
  height: 1.5rem;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
  width: 1.5rem;
`;

export const BathtubIcon = styled(DefaultBathtubIcon)`
  ${iconStyles}
`;

export const BedIcon = styled(DefaultBedIcon)`
  ${iconStyles}
`;

export const Content = styled.div<{ $hasImage: boolean }>`
  background: ${({ theme }) => theme.customTheme.colors.white};
  justify-content: space-between;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.75rem 2rem rgba(0, 0, 0, 0.1);
  ${UtilizeSpacer({
    options: [
      {
        size: '2xs',
        direction: [0],
      },
    ],
    type: 'padding',
  })}
  display: flex;
  flex-direction: column;
  ${from.tabletLandscape`
    width: 100%;
  `}

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      border-radius: 0 0 1rem 1rem;

      ${from.tabletLandscape`
        border-radius: 0 1rem 1rem 0;
        width: 50%;
      `}
    `}
`;

export const Container = styled.div`
  ${from.tabletLandscape`
    display: flex;
    height: 19.125rem;
  `}
`;

export const Item = styled.div`
  align-items: center;
  display: flex;

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.customTheme.spacing.base};
  }
`;

export const Subtitle = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  display: block;
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'vertical',
    },
    type: 'margin',
  })}
`;

export const Title = styled(Text)``;

export const Top = styled.div``;

export const ImageContainer = styled.div<{ $hasImage: boolean }>`
  border-radius: 1rem 1rem 0 0;
  display: flex;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.customTheme.colors.grey100};

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      height: 12.5rem;
      width: 100%;

      ${from.tabletLandscape`
        border-radius: 1rem 0 0 1rem;
        height: auto;
        width: 50%;
      `}
    `}
`;

export const Notification = styled(Caption)`
  color: ${({ theme }) => theme.customTheme.colors.white};
  ${from.tabletLandscape`
    font-size: 0.875rem;
  `}
`;

export const TimerIcon = styled(DefaultTimerIcon)`
  color: ${({ theme }) => theme.customTheme.colors.white};
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.656rem;
  width: 1.25rem;
`;

export const Price = styled(Text)`
  margin-right: auto;
`;

export const ButtonWrapper = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'top',
    },
    type: 'margin',
  })}
`;

export const ButtonDisruptive = styled(DefaultButton)`
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'top',
    },
    type: 'margin',
  })}
`;

export const Image = styled(DefaultImage)`
  object-fit: cover;
  width: 100%;
`;

export const TruckIcon = styled(DefaultTruckIcon)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  height: 20px;
`;

// export const Disclaimer = styled.div`
//   margin-top: ${({ theme }) => theme.customTheme.spacing.sm};
//   align-items: center;
//   display: flex;
//   justify-content: flex-start;
//   color: ${({ theme }) => theme.customTheme.colors.grey600};

export const Disclaimer = styled(Text)`
  color: ${({ theme }) => theme.customTheme.colors.grey600};
  ${UtilizeSpacer({
    specificOption: { size: 'sm', direction: 'top' },
    type: 'margin',
  })}
`;
