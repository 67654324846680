import { GlobalContext } from '$/context/global/globalContext';
import { ChargeInput } from '$/graphql/generated';
import useFeatureFlag from '$/hooks/useFeatureFlag';
import { RenewalOfferPricingTerm } from '$/model/RenewalTerm';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';

export function useDeclineOffer() {
  const { push } = useRouter();
  const handleDecline = useCallback(() => {
    void push('/renewal-offer/decline-offer', undefined, { shallow: true });
  }, [push]);

  return { handleDecline };
}

export function useGiveNotice() {
  const { push } = useRouter();
  const handleGiveNotice = useCallback(() => {
    void push('/renewal-offer/notice-to-vacate', undefined, {
      shallow: true,
    });
  }, [push]);

  return { handleGiveNotice };
}

export function useSubmitOffer() {
  const { push } = useRouter();

  const handleSubmitOnClick = useCallback(() => {
    void push('/renewal-offer/success', undefined, { shallow: true });
  }, [push]);

  return { handleSubmitOnClick };
}

export function useHandleModal() {
  const { setGlobalState, globalState } = useContext(GlobalContext);

  const rentableItemsEnabled = useFeatureFlag('RENTABLE_ITEMS');

  const { push } = useRouter();

  const handleOnClickSubmit = useCallback(
    (
      term: RenewalOfferPricingTerm,
      community?:
        | {
            id: string;
            name: string;
            address: {
              city: string;
              secondary: string | undefined;
              state: string;
              street: string;
              zipCode: string;
              latitude: number | undefined;
              longitude: number | undefined;
            };
            propertyManager: string;
          }
        | undefined,
    ) => {
      const { lengthInMonths, charges, id, baseRent, totalMonthlyCharge } =
        term;
      const currentGlobalState = globalState;
      currentGlobalState.interestedTerm = {
        id,
        amount: {
          baseRent,
          totalMonthlyCharge,
        },
        name:
          lengthInMonths > 0
            ? `${lengthInMonths} month term`
            : 'Month to month term',
        charges: charges as unknown as ChargeInput[],
        communityId: community?.id,
        communityName: community?.name,
      };
      setGlobalState(currentGlobalState);
      void push(
        rentableItemsEnabled
          ? '/renewal-offer/rentable-items'
          : '/renewal-offer/update-info',
        undefined,
        { shallow: true },
      );
    },
    [push, setGlobalState, globalState, rentableItemsEnabled],
  );

  return {
    handleOnClickSubmit,
  };
}
