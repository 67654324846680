import DefaultLoaderIcon from 'remixicon-react/Loader5FillIcon';
import styled from 'styled-components';

import spin from './animations';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.customTheme.colors.white};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export const Loader = styled(DefaultLoaderIcon)`
  animation: ${spin} 1.5s linear infinite;
  color: ${({ theme }) => theme.customTheme.colors.night400};
  height: 2.5rem;
  width: 2.5rem;
`;
